import * as React from "react";
import { Box, Flex, List } from "ui";

const ListItem = React.forwardRef(
  (
    { cover, bg = "white", children, containerProps, contentProps }: PropsType,
    ref
  ) => (
    <List.Item>
      <Flex py={10}>
        <Flex
          ref={ref}
          width={1}
          bg={bg}
          className="ant-list-item-container"
          flexWrap="nowrap"
          {...containerProps}
        >
          <Box>{cover}</Box>
          <Flex flexGrow={1} flexWrap="nowrap" {...contentProps}>
            {children}
          </Flex>
        </Flex>
      </Flex>
    </List.Item>
  )
);

export default ListItem;
