import * as React from "react";
import { Switch, useHistory, useLocation } from "react-router-dom";
import { Container, Flex, Radio } from "ui";

import NotFoundRedirect from "components/utils/NotFoundRedirect";
import Route from "components/utils/Route";
import {
  calendarRoute,
  criteriaRoute,
  priorityRoute,
  requestRoute,
  reservationsRoute,
} from "routes/constants/reservations";

import Calendar from "./Calendar";
import Chart from "./Chart";
import Criteria from "./Criteria";
import Request from "./Request";
import Section from "./Section";

const getTitles = route => {
  switch (route) {
    case requestRoute:
      return {
        title: "Pedir fechas",
        subtitle:
          "Aquí podrás realizar la solicitud de reserva de la casa del campo para una fecha determinada",
      };
    case criteriaRoute:
      return {
        title: "Criterios de Priorización de Reserva",
        subtitle:
          "Aquí podrás revisar los criterios que se acordaron para priorizar el uso de la casa del campo si dos o más la solicitan para una misma fecha",
      };
    case priorityRoute:
      return {
        title: "Ranking de Prioridad",
        subtitle:
          "Aquí podrás ver los nombres y fechas de quien ha utilizado o reservado la casa del campo",
      };
    default:
      return {
        title: "Calendario de Reservas",
        subtitle:
          "Aquí podrás ver el estado de prioridad de cada familia para el uso de la casa del campo",
      };
  }
};

const Reservations = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const onRadioChange = e => history.push(e.target.value);
  return (
    <Container width={[1, 1, 1, 4 / 5]}>
      <Flex justifyContent="center">
        <Radio.Group onChange={onRadioChange} value={pathname}>
          <Radio.Button value={calendarRoute}>Calendario</Radio.Button>
          <Radio.Button value={requestRoute}>Solicitud</Radio.Button>
          <Radio.Button value={criteriaRoute}>Criterios</Radio.Button>
          <Radio.Button value={priorityRoute}>Prioridad</Radio.Button>
        </Radio.Group>
      </Flex>
      <Section
        centered
        {...getTitles(pathname)}
        mt={20}
        className="trekan-reservations-container"
      >
        <Switch>
          <Route path={requestRoute}>
            <Request />
          </Route>
          <Route path={criteriaRoute}>
            <Criteria />
          </Route>
          <Route path={priorityRoute}>
            <Chart />
          </Route>
          <Route path={[calendarRoute, reservationsRoute]}>
            <Calendar />
          </Route>
          <NotFoundRedirect />
        </Switch>
      </Section>
    </Container>
  );
};

export default Reservations;
