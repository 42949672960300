import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { NavBar } from "components/UI";
import { aboutRoute } from "routes/constants/about";
import { homeRoute } from "routes/constants/home";
import { loginRoute } from "routes/constants/login";
import { photosRoute } from "routes/constants/photos";
import { profileRoute } from "routes/constants/profile";
import { reportsRoute } from "routes/constants/reports";
import { reservationsRoute } from "routes/constants/reservations";
import { statementsRoute } from "routes/constants/statements";
import { logout } from "utils/firebase/users";

const AppNavBar = ({ currentUser, current }) => {
  const history = useHistory();

  const onSignOut = async () => {
    await logout();
    history.push(loginRoute);
  };

  const navBarProps = {
    current: current,
    mainItem: { to: homeRoute, label: "Inicio", main: true },
    leftItems: !currentUser
      ? []
      : [
          { to: aboutRoute, label: "¿Quiénes somos?" },
          { to: statementsRoute, label: "Estados Financieros" },
          { to: reportsRoute, label: "Documentos" },
          { to: photosRoute, label: "Fotos" },
          { to: reservationsRoute, label: "Reservas" },
        ],
    rightItems: currentUser
      ? [
          { to: profileRoute, label: <SettingOutlined /> },
          {
            to: homeRoute,
            label: "Salir",
            onClick: onSignOut,
          },
        ]
      : [{ to: loginRoute, label: "Ingresar" }],
  };
  return <NavBar {...navBarProps} />;
};

export default AppNavBar;
