import * as React from "react";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { Box, Button, Dropdown, Flag, Flex, Menu } from "ui";
import LoadingButton from "ui/LoadingButton";

import Skeleton from "components/utils/Skeleton";
import { useUser } from "utils/hooks/firebase/users";

const Info = ({ item, onDelete, onMove, onEdit, allowMenu }) => {
  const { name, restricted, updated, authorId } = item;

  const [author, loading] = useUser(authorId);

  const onDeleteItem = () => onDelete(item);

  return (
    <>
      <Flex justifyContent="space-between">
        <Flag bg={restricted ? "red" : "green"}>{name}</Flag>
        {allowMenu && (
          <Dropdown
            placement="bottomRight"
            overlay={
              <FileMenu
                onEdit={onEdit}
                onDelete={onDeleteItem}
                setMoving={onMove}
              />
            }
            trigger={["click"]}
          >
            <Button type="link" size="large">
              <MoreOutlined />
            </Button>
          </Dropdown>
        )}
      </Flex>
      <Box mt={3}>Fecha: {updated.fromNow()}</Box>
      {author != null && (
        <Skeleton loading={loading}>
          <Box>
            Autor: {author.firstName} {author.lastName}
          </Box>
        </Skeleton>
      )}
    </>
  );
};

const FileMenu = ({ onEdit, onDelete, setMoving }) => (
  <Menu>
    <Menu.Item key="1">
      <LoadingButton type="link" icon={<EditOutlined />} onClick={onEdit}>
        Editar
      </LoadingButton>
    </Menu.Item>
    <Menu.Item key="2">
      <Button type="link" icon={<SelectOutlined />} onClick={setMoving}>
        Mover
      </Button>
    </Menu.Item>
    <Menu.Item key="3">
      <LoadingButton
        type="link"
        icon={<DeleteOutlined />}
        onClick={onDelete}
        color="red"
      >
        Eliminar
      </LoadingButton>
    </Menu.Item>
  </Menu>
);

Info.defaultProps = {
  allowMenu: true,
};

export default Info;
