import * as React from "react";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Box, Button, Checkbox, Form, Input, message, Select } from "ui";

import { allUsersRoute } from "routes/constants/profile";
import { createUser } from "utils/api/users";
import { useCurrentUser, useHasRole } from "utils/hooks/firebase/users";

const nameRules = [
  {
    required: true,
    message: "Por favor ingresa un nombre válido!",
  },
];

const emailRules = [
  {
    required: true,
    message: "Por favor ingresa un correo válido!",
  },
];

const New = ({ users, loadUsers }) => {
  const [currentUser] = useCurrentUser();
  const hasRole = useHasRole(110);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onFinish = values => {
    setLoading(true);
    const { firstName, lastName, mail, dependant } = values;
    const creator = dependant == null ? currentUser.id : values.creator;
    return createUser({ firstName, lastName, mail, creator })
      .then(() => {
        message.success("Usuario creado");
        form.resetFields();
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        message.error(error.message);
        setLoading(false);
      });
  };

  const options = users
    .filter(({ admin }) => admin >= 100)
    .map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

  return (
    <Form
      size="large"
      name="newUser"
      form={form}
      initialValues={{ dependant: true, creator: currentUser.id }}
      onFinish={onFinish}
    >
      <Form.Item name="firstName" hasFeedback rules={nameRules}>
        <Input prefix={<UserOutlined />} placeholder="Nombre" />
      </Form.Item>
      <Form.Item name="lastName" hasFeedback rules={nameRules}>
        <Input prefix={<UserOutlined />} placeholder="Apellido" />
      </Form.Item>
      <Form.Item name="mail" hasFeedback rules={emailRules}>
        <Input prefix={<MailOutlined />} placeholder="Correo electrónico" />
      </Form.Item>
      {hasRole && (
        <>
          <Form.Item name="dependant" valuePropName="checked">
            <Checkbox>Depende de alguien</Checkbox>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => prev.dependant !== curr.dependant}
          >
            {({ getFieldValue }) =>
              getFieldValue("dependant") && (
                <Form.Item label="Depende de" name="creator">
                  <Select options={options} defaultValue={currentUser.id} />
                </Form.Item>
              )
            }
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Button width={1} type="primary" htmlType="submit" loading={loading}>
          Crear
        </Button>
        <Box width={1} mt={2} textAlign="center">
          <Link to={allUsersRoute}>Cancelar</Link>
        </Box>
      </Form.Item>
    </Form>
  );
};

export default New;
