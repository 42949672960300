import * as React from "react";
import { Box, Container } from "ui";
import Section from "ui/Section";

const NotFound = () => (
  <Container width={[1, 1, 1, 4 / 5]}>
    <Section
      title="NO ENCONTRADO"
      mt={20}
      className="trekan-not-found-container"
    >
      <Box fontSize={24}>
        Lo siento, no hemos encontrado lo que estabas buscando.
      </Box>
    </Section>
  </Container>
);

export default NotFound;
