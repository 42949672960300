import { photosRoute } from "routes/constants/photos";
import { reportsRoute } from "routes/constants/reports";
import { statementsRoute } from "routes/constants/statements";

export const MAX_FOLDERS = 5;

export const downloadLink = url => {
  setTimeout(() => {
    const response = {
      file: url,
    };
    window.open(response.file, "_blank");
  }, 100);
};

export const getFileView = route => {
  switch (route) {
    case photosRoute:
      return "files";
    case reportsRoute:
      return "reports";
    case statementsRoute:
      return "statements";
    default:
      throw new Error("No existe esa ruta");
  }
};

export const getPageName = view => {
  switch (view) {
    case photosRoute:
      return "Fotos";
    case reportsRoute:
      return "Informes";
    case statementsRoute:
      return "Estados";
    default:
      throw new Error("No existe esa vista");
  }
};
