const loremIpsum = {
	title: "Lorem Ipsum",
	subtitle1: "Nullam Sodales",
	paragraph1:
		"Maecenas eleifend eu dui laoreet convallis. Nullam in sodales libero. Pellentesque pellentesque euismod blandit. Curabitur eget nisi vulputate, pulvinar lorem vitae, tincidunt velit. Donec rutrum ex quis urna faucibus, in rhoncus justo ullamcorper. Praesent tempus sollicitudin commodo. Integer eu erat ac eros lacinia faucibus at in ex. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
	paragraph2:
		"Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque sed eros luctus, auctor velit id, malesuada felis. Suspendisse id enim ante. Mauris sed justo sed justo convallis viverra. Vivamus est massa, lacinia ac lobortis sed, finibus non leo. Nunc vel vestibulum odio. Duis nec tempus urna. Maecenas egestas sit amet magna eget ornare. Cras vitae condimentum quam. In nec hendrerit sem. Cras condimentum magna non massa sollicitudin faucibus.",
	subtitle2: "Maecenas Eleifend",
	paragraph3:
		"Suspendisse in molestie elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ac metus varius, aliquet orci vitae, sollicitudin nulla. Phasellus pulvinar lectus nec sapien gravida scelerisque. Duis elementum ex neque, a tristique erat ullamcorper in. Suspendisse aliquam porta cursus. Phasellus sit amet sem varius, auctor arcu id, pharetra arcu. Mauris vel ultricies risus. Integer magna libero, posuere quis dolor non, mollis gravida enim.",
	paragraph4:
		"Sed at erat tellus. Sed laoreet euismod tempus. Vivamus mattis lobortis suscipit. Maecenas efficitur lorem a erat consectetur elementum. Pellentesque non aliquam nulla, nec auctor metus. Suspendisse potenti. Donec eget egestas libero, a semper neque. Proin aliquam felis augue, sit amet cursus libero laoreet vel. Pellentesque orci metus, sollicitudin at ultrices a, cursus ut nulla. Sed congue mi in tellus fringilla condimentum."
};

export default loremIpsum;
