import * as React from "react";
import { Chart } from "react-google-charts";
import { Box } from "ui";

import Skeleton from "components/utils/Skeleton";
import { useAdmins } from "utils/hooks/firebase/users";

const BarChart = () => {
  const [admins, loading] = useAdmins(100);

  const data = [["Usuario", "Noches"]];
  admins.forEach(({ firstName, lastName, points }) => {
    data.push([`${firstName} ${lastName}`, points]);
  });

  return (
    <Skeleton loading={loading}>
      <Box width={1}>
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data}
          options={{
            chartArea: { width: "70%" },
            colors: ["#b0120a"],
            hAxis: {
              title: "Noches acumuladas",
              minValue: 0,
            },
            isStacked: true,
          }}
        />
      </Box>
    </Skeleton>
  );
};

export default BarChart;
