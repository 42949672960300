import AntIcon from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Affix as AntAffix,
  Alert as AntAlert,
  Avatar as AntAvatar,
  Badge as AntBadge,
  Breadcrumb as AntBreadcrumb,
  Button as AntButton,
  Calendar as AntCalendar,
  Card as AntCard,
  Carousel as AntCarousel,
  Checkbox as AntCheckbox,
  Comment as AntComment,
  DatePicker as AntDatePicker,
  Descriptions as AntDescriptions,
  Divider as AntDivider,
  Dropdown as AntDropdown,
  Form as AntForm,
  Input as AntInput,
  InputNumber as AntInputNumber,
  Layout as AntLayout,
  List as AntList,
  Menu as AntMenu,
  message as AntMessage,
  Modal as AntModal,
  PageHeader as AntPageHeader,
  Popconfirm as AntPopconfirm,
  Progress as AntProgress,
  Radio as AntRadio,
  Rate as AntRate,
  Select as AntSelect,
  Skeleton as AntSkeleton,
  Statistic as AntStatistic,
  Steps as AntSteps,
  Switch as AntSwitch,
  Tabs as AntTabs,
  Tag as AntTag,
  TimePicker as AntTimePicker,
  Tooltip as AntTooltip,
  Typography as AntTypography,
  Upload as AntUpload,
} from "antd";
import { Flag as PclnFlag } from "pcln-design-system";
import {
  alignSelf,
  background,
  border,
  borders,
  color,
  flex,
  flexbox,
  fontSize,
  height,
  layout,
  maxHeight,
  minHeight,
  order,
  position,
  space,
  system,
  typography,
  width,
} from "styled-system";

import getShouldForwardProp from "./getShouldForwardProp";

export { default as Container } from "./Container";

export const Box = styled("div")(
  fontSize,
  flex,
  order,
  alignSelf,
  borders,
  color,
  height,
  maxHeight,
  minHeight,
  space,
  color,
  flexbox,
  typography,
  border,
  position,
  background,
  width,
  system({ visibility: true })
);
export const Flex = styled(Box)({ display: "flex" });
Flex.defaultProps = {
  flexWrap: "wrap",
};
export const Span = styled("span")(
  space,
  layout,
  color,
  typography,
  background
);

export const Image = styled("img")(space, color, layout, border, position);
Image.defaultProps = { width: 1, style: { objectFit: "cover" } };

export const IFrame = styled("iframe")(space, layout, position, border);

export const Layout = AntLayout;
Layout.Content = styled(AntLayout.Content)(space, color);
Layout.Footer = styled(AntLayout.Footer, getShouldForwardProp())(typography);

export const Menu = AntMenu;

export const Icon = styled(AntIcon)(layout, system({ fill: true }));

export const Avatar = AntAvatar;

export const Form = styled(AntForm)(layout);
Form.Item = AntForm.Item;
Form.useForm = AntForm.useForm;

export const Input = styled(AntInput)(layout, color, border);
Input.Password = styled(AntInput.Password)(layout);
Input.TextArea = styled(AntInput.TextArea)(layout);
Input.Search = styled(AntInput.Search)(layout);

export const InputNumber = styled(AntInputNumber)(layout, color, border);

export const Button = styled(AntButton)(layout, position, color, typography);
Button.Group = AntButton.Group;

export const Checkbox = AntCheckbox;

export const Text = styled("p", getShouldForwardProp())(
  typography,
  color,
  layout
);
Text.defaultProps = {
  mb: 0,
};

export const Typography = AntTypography;

export const Paragraph = AntTypography.Paragraph;

export const Title = styled(
  AntTypography.Title,
  getShouldForwardProp(["level"])
)(space, typography, color, layout);

export const PageHeader = AntPageHeader;

export const DatePicker = styled(AntDatePicker)(layout, border);
DatePicker.defaultProps = {
  style: { width: "100%" },
};
DatePicker.RangePicker = styled(AntDatePicker.RangePicker)(layout, border);
DatePicker.RangePicker.defaultProps = {
  style: { width: "100%" },
};

export const Tooltip = styled(AntTooltip)(layout);

export const List = AntList;

export const Statistic = AntStatistic;

export const Rate = AntRate;

export const Badge = AntBadge;

export const Select = styled(AntSelect)(layout, border);
Select.Option = AntSelect.Option;

export const Card = styled(AntCard)(layout, space, color);
Card.Meta = AntCard.Meta;

export const Tabs = AntTabs;

export const Steps = AntSteps;

export const Carousel = styled(AntCarousel)(layout, color, typography);

export const Skeleton = AntSkeleton;

export const Affix = AntAffix;

export const Comment = AntComment;

export const Upload = AntUpload;
Upload.Dragger = AntUpload.Dragger;

export const Tag = AntTag;

export const Descriptions = AntDescriptions;

export const Popconfirm = AntPopconfirm;

export const Radio = AntRadio;
Radio.Group = AntRadio.Group;
Radio.Button = AntRadio.Button;

export const Divider = AntDivider;

export const Calendar = AntCalendar;

export const TimePicker = AntTimePicker;

export const Modal = AntModal;

export const Alert = AntAlert;

export const message = AntMessage;

export const Switch = AntSwitch;

export const Breadcrumb = AntBreadcrumb;
Breadcrumb.Item = AntBreadcrumb.Item;
Breadcrumb.Separator = AntBreadcrumb.Separator;

export const Dropdown = AntDropdown;
Dropdown.Button = AntDropdown.Button;

export const Flag = PclnFlag;

export const Progress = AntProgress;
