import * as App from "components/App";
import * as Login from "components/Login";
import * as Statements from "components/Statements";
import * as Reports from "components/Reports";
import * as Photos from "components/Photos";
import * as Profile from "components/Profile";
import * as Calendar from "components/Calendar";

const reducers = {
  app: App,
  login: Login,
  statements: Statements,
  reports: Reports,
  files: Photos,
  profile: Profile,
  calendar: Calendar
};

export default reducers;
