import { getFilesActions, getFilesReducer } from "utils/redux/files";

const initialState = {
  routeStatements: ["Estados"],
  refStatements: "Estados",
};

export const reducer = (state = initialState, action) =>
  getFilesReducer("Statements", state, action);

export const actions = dispatch =>
  getFilesActions("STATEMENTS", "Estados", dispatch);
