import * as React from "react";
import { useMeasure } from "react-use";
import { Box, Flex } from "ui";
import ListItem from "ui/ListItem";

import Skeleton from "components/utils/Skeleton";
import { useUser } from "utils/hooks/firebase/users";

const Item = ({ user, query }) => {
  const [containerRef] = useMeasure();
  const itemHeight = 100;
  const contentPadding = 10;
  const { firstName, lastName, mail, creator } = user;
  const [
    { firstName: creatorName, lastName: creatorLastName },
    loading,
  ] = useUser(creator);
  return (
    <ListItem
      ref={containerRef}
      bg="hellblue"
      containerProps={{ height: itemHeight }}
      contentProps={{ p: contentPadding }}
    >
      <Flex flexDirection="column">
        <Box width={1} fontSize={18}>{`${firstName} ${lastName}`}</Box>
        <Box width={1}>{mail}</Box>
        {creator && (
          <Skeleton loading={loading}>
            <Box width={1}>
              Acumula Puntos: {`${creatorName} ${creatorLastName}`}
            </Box>
          </Skeleton>
        )}
      </Flex>
    </ListItem>
  );
};

export default Item;
