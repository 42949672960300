import * as React from "react";
import cx from "classnames";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Box, Flex } from "ui";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { useCalendarEvents } from "utils/hooks/firebase/requests";

import Cell from "./Cell";
import Legend from "./Legend";
import List from "./List";
import Toolbar from "./Toolbar";

const localizer = momentLocalizer(moment);

export const STATUS = {
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  LOST: "LOST",
  CANCELLED: "CANCELLED",
};

const ReservationsCalendar = () => {
  const [open] = React.useState(null);
  const [currentDate, setCurrentMonth] = React.useState(moment());
  const [requests, loading] = useCalendarEvents(currentDate.month() + 1);

  const checkDateStatus = (date, status) =>
    requests.some(request => {
      const [from, to] = [moment(request.from), moment(request.to)];
      if (
        request.status === status &&
        date.isBetween(from, to, undefined, "[]")
      )
        return true;
      return false;
    });

  const toolbar = () => (
    <Toolbar month={currentDate} setMonth={setCurrentMonth} />
  );
  const dayPropGetter = d => {
    const date = moment(d);
    return {
      className: cx({
        "rbc-day-bg-past": date.isBefore(moment(), "day"),
        "rbc-day-bg-reserved": false,
      }),
    };
  };
  const dateHeader = ({ date: d, label }) => {
    const date = moment(d);
    return (
      <Cell
        date={date}
        past={date.isBefore(moment(), "day")}
        disabled={!date.isSame(currentDate, "month")}
        requested={checkDateStatus(date, STATUS.PENDING)}
        confirmed={checkDateStatus(date, STATUS.CONFIRMED)}
        today={date.isSame(moment(), "day")}
      />
    );
  };
  return (
    <Flex width={1} className="trekan-reservations-calendar">
      <Box width={1} p={2}>
        <Calendar
          date={new Date(currentDate.format())}
          culture="es"
          localizer={localizer}
          events={[]}
          startAccessor="start"
          endAccessor="end"
          popup={false}
          views={["month"]}
          dayPropGetter={dayPropGetter}
          components={{
            dateHeader,
            toolbar,
          }}
        />
      </Box>
      <Flex width={1} justifyContent="flex-end">
        <Legend
          available
          requested={requests.some(({ status }) => status === STATUS.PENDING)}
          confirmed={requests.some(({ status }) => status === STATUS.CONFIRMED)}
        />
      </Flex>
      <List open={open} reservations={requests} loading={loading} />
    </Flex>
  );
};

export default ReservationsCalendar;
