import * as React from "react";

import { ActionsContext } from "components/App/Providers";

export { useSelector, shallowEqual } from "react-redux";

export const useActions = (actionsMap = actions => actions) => {
  const actions = React.useContext(ActionsContext);
  return actionsMap(actions);
};
