import { firestore } from "config";
import { getPendingNights } from "utils";

const initialState = {
  calendarDate: new Date(),
  events: [],
  error: "",
  showing: "calendar"
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "RESET_ERROR":
      return { ...state, error: payload };
    case "SET_SHOWING":
      return { ...state, showing: payload };
    case "SET_CURRENT_DATE":
      return { ...state, calendarDate: payload };
    case "NEW_EVENTS":
      return {
        ...state,
        events: payload.events
      };
    case "DEADLINE_ERROR":
      return { ...state, error: "Ya se confirmó un pedido en esas fechas." };
    case "PRIORITY_ERROR":
      return {
        ...state,
        error: "Usuario con más prioridad pidió esas fechas."
      };
    case "REQUEST_ERROR":
      return state;
    default:
      return state;
  }
};

const updatePoints = (doc, night, pointsNight) => {
  const { user } = doc.data();
  return firestore
    .collection("users")
    .doc(user)
    .collection("requests")
    .doc(doc.id)
    .update({
      [`dates.${night}`]: pointsNight
    })
    .then(() =>
      getPendingNights(user).then(pending =>
        firestore
          .collection("users")
          .doc(user)
          .update({ pending })
      )
    );
};

const createRequest = async (
  currentUser,
  form,
  deadline,
  currentRequests = []
) => {
  const timeDiff = form.startDate.toDate().getTime() - deadline.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);
  await deadline.setDate(
    deadline.getDate() + Math.min(7, Math.floor(daysDiff / 2))
  );
  const start = new Date(
    form.startDate.toDate().getFullYear(),
    form.startDate.toDate().getMonth(),
    form.startDate.toDate().getDate(),
    12
  );
  const end = new Date(
    form.endDate.toDate().getFullYear(),
    form.endDate.toDate().getMonth(),
    form.endDate.toDate().getDate(),
    12
  );

  // Para ver cuantos puntos hay por noche en una reserva
  const daysPoints = {};
  for (
    let night = new Date(start);
    night < end;
    night.setDate(night.getDate() + 1)
  ) {
    let currentNight = new Date(night);
    // Revisar que eventos coinciden en la noche "currentNight"
    let docsNight = currentRequests
      ? currentRequests.filter(
          event =>
            event.data().start.toDate() <= currentNight &&
            event.data().end.toDate() >= currentNight
        )
      : [];
    // El número de eventos es la cantidad de personas que hay en la casa
    let personsNight = docsNight.length;
    // Los puntos van a estar repartidos entre todos los que se estan quedando
    let nightPoints = Math.ceil(100 / (personsNight + 1)) / 100;
    // Actualizar los puntos de las otras reservas
    //console.log("Noche", currentNight, personsNight, nightPoints);
    await Promise.all(
      docsNight.map(doc => updatePoints(doc, currentNight, nightPoints))
    );
    daysPoints[currentNight] = nightPoints;
  }
  //console.log("Puntos totales", daysPoints);

  return firestore
    .collection("campoRequests")
    .add({
      start,
      end,
      share: form.share,
      user: currentUser.creator ? currentUser.creator : currentUser.uid,
      deadline: new Date(
        deadline.getFullYear(),
        deadline.getMonth(),
        deadline.getDate(),
        0
      ),
      status: false // Para ver si se ha confirmado o no
    })
    .then(docRef => {
      console.log(
        "reserva",
        docRef.id,
        currentUser.creator ? currentUser.creator : currentUser.uid
      );
      return firestore
        .collection("users")
        .doc(currentUser.creator ? currentUser.creator : currentUser.uid)
        .collection("requests")
        .doc(docRef.id)
        .set({ dates: daysPoints });
    })
    .then(() =>
      getPendingNights(
        currentUser.creator ? currentUser.creator : currentUser.uid
      ).then(pending =>
        firestore
          .collection("users")
          .doc(currentUser.creator ? currentUser.creator : currentUser.uid)
          .update({ pending })
      )
    );
};

const isPossible = (currentUser, share, request) => {
  // Revisar si es posible hacer juntas las dos reservas (retornar true),
  // borrar las otras reserva y crear la nueva (retornar ID de la rserva a borrar)
  // No se puede crear la reserva porque la que esta tiene más prioridad
  const requestId = request.id;
  const requestData = request.data();
  return requestData.share && share
    ? true
    : firestore
        .collection("users")
        .doc(requestData.user)
        .get()
        .then(userDoc =>
          userDoc.data().points + userDoc.data().pending >
          currentUser.points + currentUser.pending
            ? { id: requestId, user: requestData.user }
            : false
        );
};

export const actions = dispatch => ({
  resetError: (error = "") => dispatch({ type: "RESET_ERROR", payload: error }),
  setShowing: showing => dispatch({ type: "SET_SHOWING", payload: showing }),
  createRequest: async (currentUser, form) => {
    const requestsRef = firestore.collection("campoRequests");
    const startPromise = requestsRef
      .where("start", ">=", form.startDate.toDate())
      .where("start", "<=", form.endDate.toDate())
      .get();
    const endPromise = requestsRef
      .where("end", ">=", form.startDate.toDate())
      .where("end", "<=", form.endDate.toDate())
      .get();
    const currentRequests = await Promise.all([startPromise, endPromise]).then(
      ([startValues, endValues]) => {
        const events = {};
        startValues.docs.concat(endValues.docs).forEach(doc => {
          if (!events[doc.id]) {
            events[doc.id] = doc;
          }
        });
        return Object.values(events);
      }
    );
    //const currentRequests = startValues.docs.concat(endValues.docs);
    const deadline = new Date(); //Parte siendo hoy, despues se modifica.
    if (!currentRequests.length) {
      return createRequest(currentUser, form, deadline)
        .then(() => {
          dispatch({ type: "ADD_REQUEST" });
          return true;
        })
        .catch(err => {
          dispatch({ type: "REQUEST_ERROR", payload: err });
          return true;
        });
    } else {
      // Hay que revisar si alguna de las solicitudes con las que coincide ya cumplió el deadline
      if (currentRequests.some(reqDoc => reqDoc.data().deadline <= deadline)) {
        // Ya se cumplió el deadline
        dispatch({ type: "DEADLINE_ERROR" });
        return false;
      } else {
        // Revisar si se puede compartir
        return Promise.all(
          currentRequests.map(request =>
            isPossible(currentUser, form.share, request)
          )
        ).then(possible => {
          // Revisar si hay un false en la lista. Esto significa que no se puede hacer la reserva por puntos
          if (possible.some(req => !req)) {
            // No se puede hacer la reserva
            dispatch({ type: "PRIORITY_ERROR" });
            return false;
          } else {
            // Se deben borrar las reservas que tienen menos prioridad
            Promise.all(
              possible.map(req => {
                if (req !== true) {
                  const userRef = firestore.collection("users").doc(req.user);
                  const reqRef = firestore
                    .collection("users")
                    .doc(req.user)
                    .collection("requests")
                    .doc(req.id);

                  return Promise.all([
                    firestore // Borrar el documento en la lista de documentos
                      .collection("campoRequests")
                      .doc(req.id)
                      .delete(),
                    reqRef // Modificar los puntos pendientes de un usuario
                      .get()
                      .then(snapshotReq =>
                        Object.values(snapshotReq.data().dates).reduce(
                          (a, b) => a + b,
                          0
                        )
                      )
                      .then(totalReq =>
                        userRef
                          .get()
                          .then(snapshotUser => {
                            const { pending } = snapshotUser.data();
                            return userRef.update({
                              pending: pending - totalReq
                            });
                          })
                          .then(reqRef.delete())
                      )
                  ]);
                }
                return undefined;
              })
            ).then(() =>
              createRequest(currentUser, form, deadline, currentRequests)
                .then(() => {
                  dispatch({ type: "ADD_REQUEST" });
                  return true;
                })
                .catch(err_1 => {
                  dispatch({ type: "REQUEST_ERROR", payload: err_1 });
                  return true;
                })
            );
          }
        });
      }
    }
  },
  changeDate: date => {
    dispatch({ type: "SET_CURRENT_DATE", payload: date.toISOString() });
  },
  updateEvents: eventsDocs => {
    dispatch({
      type: "NEW_EVENTS",
      payload: {
        events: eventsDocs.map(doc => {
          const data = doc.data();
          return {
            title: data.user,
            start: data.start.toDate(),
            end: data.end.toDate(),
            allDay: true,
            user: data.user,
            deadline: data.deadline.toDate(),
            status: data.status
          };
        })
      }
    });
  }
});
