import * as React from "react";
import { Route } from "react-router-dom";

import Authed from "./Authed";

const MyRoute = ({ authed, children, admin, ...rest }) => {
  switch (authed) {
    case true:
      return (
        <Authed header admin={admin}>
          <Route {...rest}>{children}</Route>
        </Authed>
      );
    case false:
      return (
        <Authed not header admin={admin}>
          <Route {...rest}>{children}</Route>
        </Authed>
      );
    default:
      return <Route {...rest}>{children}</Route>;
  }
};

MyRoute.defaultProps = {
  authed: null,
  role: null,
};

export default MyRoute;
