import * as React from "react";

import Files from "components/Files/Files";
import { useReportsState } from "utils/redux/files";
import { ADMIN_LEVEL } from "utils/users";

const ReportsContainer = ({ currentUser }) => {
  const route = useReportsState("routeReports");

  return (
    <Files currentUser={currentUser} route={route} restricted={ADMIN_LEVEL} />
  );
};

export default ReportsContainer;
