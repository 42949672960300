import * as React from "react";
import { Box } from "ui";

const Dot = ({ size, color, bordered }) => {
  return (
    <Box
      bg={color}
      width={size}
      height={size}
      borderRadius="50%"
      display="inline-block"
      mr={1}
      border={bordered && "1px solid black"}
    />
  );
};

export default Dot;
