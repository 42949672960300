import * as React from "react";
import { Box, List } from "ui";

import Item from "./Item";

const UserList = ({ currentUser, users, loading, query }) => {
  return (
    <Box className="trekan-user-list" width={1}>
      <List
        itemLayout={"vertical"}
        size="large"
        loading={loading}
        dataSource={users}
        split={false}
        renderItem={item => (
          <Item query={query} user={item} currentUser={currentUser} />
        )}
      />
    </Box>
  );
};

UserList.defaultProps = {
  loading: false,
  query: {},
};

export default UserList;
