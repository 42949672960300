import * as React from "react";

export const useDocument = (ref, deps = []) => {
  const [state, setState] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = ref.onSnapshot(
      doc => {
        setState({ id: doc.id, ...doc.data() });
        setLoading(false);
      },
      error => {
        console.log(error);
        setState(null);
        setLoading(false);
      }
    );

    return () => {
      setLoading(false);
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, loading];
};

export const useCollection = (ref, deps = []) => {
  const [state, setState] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = ref.onSnapshot(
      snap => {
        setState(snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      },
      error => {
        console.log(error);
        setState(null);
        setLoading(false);
      }
    );

    return () => {
      setLoading(false);
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, loading];
};

export const useDatabaseValue = (ref, deps = []) => {
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (ref) {
      ref.on(
        "value",
        snap => {
          setState({ ...snap.val(), id: snap.key });
          setLoading(false);
        },
        error => {
          console.log(error);
          setState({});
          setError(error.message);
          setLoading(false);
        }
      );

      return () => {
        setLoading(false);
        ref.off();
      };
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, loading, error];
};

export const useDatabaseValues = (ref, deps = []) => {
  const [state, setState] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    ref.on(
      "value",
      snap => {
        const entries = Object.entries(snap.val() || {});
        setState(entries.map(([id, value]) => ({ id, ...value })));
        setLoading(false);
      },
      error => {
        console.log(error);
        setState(null);
        setLoading(false);
      }
    );

    return () => {
      setLoading(false);
      ref.off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, loading];
};
