import * as React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { Box, Button, DatePicker, Flex } from "ui";

const Toolbar = ({ month, setMonth }) => {
  const onChangeMonth = newMonth => setMonth(newMonth);
  const onToday = () => setMonth(moment());
  const onPrev = () => setMonth(month => month.clone().subtract(1, "month"));
  const onNext = () => setMonth(month => month.clone().add(1, "month"));
  return (
    <Flex width={1} pl={2} alignItems="center" flexDirection="column" mb={2}>
      <Box width={[1, 1 / 2, 1 / 5]}>
        <DatePicker
          onChange={onChangeMonth}
          picker="month"
          allowClear={false}
          bordered={false}
          value={month}
          format={"MMMM YYYY"}
        />
      </Box>
      <Flex alignSelf="flex-start">
        <Box>
          <Button.Group>
            <Button type="normal" onClick={onPrev} icon={<LeftOutlined />} />
            <Button type="normal" onClick={onNext} icon={<RightOutlined />} />
          </Button.Group>
        </Box>
        <Box ml={2}>
          <Button type="normal" onClick={onToday}>
            Hoy
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Toolbar;
