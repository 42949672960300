import firebase from "firebase";

// Firebase config
let config;
//////////////////////////
//////////
////////////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////////////
///////////////////////////
///////////////////////////////////////////
////////////////////////////////////
///////////////////////////////////////////////
////////////////////////////////
//
////////
config = {
  apiKey: "AIzaSyABwEpFoSHbu-GgTXrknbXHrBpznCaP_w8",
  authDomain: "staging-campo.firebaseapp.com",
  databaseURL: "https://staging-campo.firebaseio.com",
  projectId: "staging-campo",
  storageBucket: "staging-campo.appspot.com",
  messagingSenderId: "40143414969",
  appId: "1:40143414969:web:41e778e93e80b2baf35d68",
  measurementId: "G-NVRDJ2D781",
};
/////////

firebase.initializeApp(config);

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const analytics = firebase.analytics();
