import * as React from "react";
import { Box, Flex } from "ui";

const Criteria = () => {
  return (
    <Flex flexDirection="column">
      <Box mb={2}>
        El criterio fundamental para priorizar el uso de la casa, en caso que
        dos o más coincidan en reservar una fecha, es asignarla siempre a quien
        la haya usado menos. Para eso el sistema llevará un registro acumulado
        de las Reservas de la casa, entendidas estas como la cantidad de noches
        de alojamiento que ha reservado cada grupo familiar. El sistema llevará
        el registro para cada uno de los siguientes grupos familiares:
      </Box>
      <Box ml={3}>a. María Elena y familia</Box>
      <Box ml={3}>b. Arturo s.j</Box>
      <Box ml={3}>c. Pablo y familia</Box>
      <Box ml={3} mb={2}>
        d. Juan Andrés y familia
      </Box>
      <Box mb={2}>
        El tata Arturo y la Mele tienen su pieza disponible siempre y no es
        necesario que reserven.
      </Box>
      <Box mb={2}>
        De esta manera cualquier miembro de la familia podrá solicitar en esta
        página el uso de la casa, indicando la fecha de llegada y salida,
        cuántas de las 4 piezas utilizará y si está disponible para compartir la
        estadía en caso que queden piezas sin ocupar. El sistema enviará esta
        solicitud a todos y dará un plazo para que cualquier otro interesado en
        esa fecha, con menor registro de Reservas, haga también su solicitud.
        Trascurrido el plazo, el sistema confirmará la solicitud de quien tenga
        el menor registro de Reservas, contabilizará el número de noches
        reservadas en la cuenta de la respectiva familia y enviará el aviso
        correspondiente. En caso que, por haber indicado disposición a compartir
        la estadía, dos o más familias coincidan en determinadas fechas, el
        sistema contabilizará el número de noches compartidas ponderadas por 1/2
        o 1/3 según sean dos o tres familias las que compartieron la casa. Una
        vez confirmada una reserva, nadie más puede solicitar las fechas
        reservadas.
      </Box>
      <Box mb={2}>
        El plazo que dará el sistema a partir de la presentación de una
        solicitud de reserva para presentar nuevas solicitudes sobre una misma
        fecha será de hasta 30 días antes de la fecha de inicio de la reserva
        solicitada. Si la solicitud se presenta con menos de 30 días a la fecha
        de inicio de la reserva, el plazo será hasta 7 días antes de la fecha de
        inicio de la reserva solicitada. Si la solicitud se presenta con menos
        de 7 días a la fecha de inicio de la reserva, la confirmación de la
        solicitud será instantánea.
      </Box>
      <Box mb={2}>
        El uso de la casa por el día, sin alojamiento, no requiere solicitar una
        reserva ni será registrado en Reservas. Sin embargo, se requiere que el
        interesado dé aviso a través del sistema en el botón Aviso de paseo por
        el día, el cual enviará un mail automático a todos. El sistema advertirá
        además si alguien tiene reservada la casa en esa fecha para que el
        solicitante, por deferencia, consulte a quien tiene la reserva si no hay
        inconveniente en ir por el día al campo.
      </Box>
    </Flex>
  );
};

export default Criteria;
