import React from "react";
import {
  FadeCard,
  Box,
  Flex,
  Radio,
  LoadingButton,
  Banner
} from "components/UI";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

const RequestDates = ({
  today,
  form,
  setValues,
  focusedInput,
  setFocused,
  createRequest,
  error,
  windowSize
}) => (
  <FadeCard>
    {error ? (
      <Box m={1}>
        <Banner mx={1} my={2} showIcon={false} bg="red">
          {error}
        </Banner>
      </Box>
    ) : null}
    <Flex my={2} justifyContent="center">
      <Radio.Group
        value={form.share}
        onChange={e => setValues({ ...form, share: e.target.value })}
      >
        <Radio.Button value={false}>Solo</Radio.Button>
        <Radio.Button value={true}>Compartir</Radio.Button>
      </Radio.Group>
    </Flex>
    <Box mb={2}>
      <DateRangePicker
        startDate={form.startDate} // momentPropTypes.momentObj or null,
        startDateId="INICIO" // PropTypes.string.isRequired,
        startDatePlaceholderText="Inicio"
        endDate={form.endDate} // momentPropTypes.momentObj or null,
        endDateId="FIN" // PropTypes.string.isRequired,
        endDatePlaceholderText="Fin"
        onDatesChange={({ startDate, endDate }) =>
          setValues({ ...form, startDate, endDate })
        } // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focused => setFocused(focused)} // PropTypes.func.isRequired,
        showClearDates={true}
        orientation={windowSize.width <= 650 ? "vertical" : "horizontal"}
        hideKeyboardShortcutsPanel
        maxDate={
          new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
        }
      />
    </Box>
    <Box width={1} p={1}>
      <LoadingButton type="primary" width={1} onClick={createRequest}>
        Pedir fechas
      </LoadingButton>
    </Box>
  </FadeCard>
);

export default RequestDates;
