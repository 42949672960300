import * as React from "react";
import moment from "moment";
import { Box, Flex, List } from "ui";

import Item from "./Item";

const ReservationList = ({ open, reservations, loading }) => {
  let titles = [];
  let data = {};
  if (open != null) {
    titles = [open];
    data = {
      [open.format("YYYY-MM-DD")]: reservations.filter(({ from, to }) =>
        open.isBetween(from, to, undefined, "[]")
      ),
    };
  } else {
    reservations.forEach(res => {
      if (data[res.from]) {
        data[res.from].push(res);
      } else {
        data[res.from] = [res];
        titles.push(moment(res.from));
      }
    });
    titles.sort((a, b) => (a.isBefore(b) ? -1 : 1));
  }
  return titles.map(title => (
    <Flex key={title.format("YYYYMMDD")} flexDirection="column">
      <Box
        width={1}
        mb={2}
        mt={2}
        fontSize={16}
        color="black"
        textAlign="center"
      >
        {title.format("DD [de] MMMM [de] YYYY")}
      </Box>
      <Box className="trekan-reservations-list" width={1}>
        <List
          itemLayout={"vertical"}
          size="large"
          loading={loading}
          dataSource={data[title.format("YYYY-MM-DD")]}
          renderItem={item => <Item reservation={item} />}
        />
      </Box>
    </Flex>
  ));
};

ReservationList.defaultProps = {
  loading: false,
  query: {},
};

export default ReservationList;
