import * as React from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Checkbox, Form, Input } from "ui";

import { getPageName } from "utils/files";
import { updateFile, updateFolder } from "utils/firebase/files";
import useFocusInput from "utils/hooks/useFocusInput";
import useKeyPress from "utils/hooks/useKeyPress";
import { ADMIN_LEVEL } from "utils/users";

const Edit = ({
  currentUser,
  route,
  restricted: currentRestriction,
  item,
  onEdit,
}) => {
  const { pathname } = useLocation();
  const pageName = getPageName(pathname);

  const [, setLoading] = React.useState(false);

  // Hook para poner el foco en el input que se le da el ref
  const [folderRef, toggleFocus] = useFocusInput();

  React.useEffect(() => {
    toggleFocus(true);
    return toggleFocus(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { type } = item;
  // Solamente pueden crear carpetas restringidas los usuarios autorizados
  const editable = type === "folder" && currentUser.admin >= ADMIN_LEVEL;

  const [form] = Form.useForm();
  const esc = useKeyPress("Escape");

  React.useEffect(() => {
    if (esc) onEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esc]);

  const onFinish = async ({ name, restricted }) => {
    if (item.name === name && item.restricted === restricted) {
      onEdit();
      return;
    }
    setLoading(true);
    try {
      switch (type) {
        case "folder":
          const folder = {
            ...item,
            name,
            restricted: restricted || currentRestriction,
            updated: moment(),
          };
          onEdit();
          await updateFolder(pageName, folder);
          break;

        default:
          const file = {
            ...item,
            name,
          };
          onEdit();
          await updateFile(pageName, file);
          break;
      }
    } catch (error) {
      console.log("Error actualizando", error);
    }
    setLoading(false);
  };

  const { name, restricted } = item;
  return (
    <Form
      size="large"
      name="editfile"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{ name, restricted }}
    >
      <Form.Item name="name">
        <Input mt={1} id="name" ref={folderRef} />
      </Form.Item>
      {editable && (
        <Form.Item name="restricted" valuePropName="checked">
          <Checkbox>Restringido</Checkbox>
        </Form.Item>
      )}
    </Form>
  );
};

export default Edit;
