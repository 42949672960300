import React from "react";
import { Switch } from "react-router-dom";

import About from "components/About";
import { AppContainer, AppNavBar } from "components/App";
import Calendar from "components/Calendar";
import Home from "components/Home";
import Login from "components/Login";
import NotFound from "components/NotFound";
import Photos from "components/Photos";
import Profile from "components/Profile";
import Reports from "components/Reports";
import Reservations from "components/Reservations";
import Statements from "components/Statements";
import LoginRedirect from "components/utils/LoginRedirect";
import NotFoundRedirect from "components/utils/NotFoundRedirect";
import Route from "components/utils/Route";
import { useCurrentUser, useIsAuthed } from "utils/hooks/firebase/users";

import { aboutRoute } from "./constants/about";
import { calendarRoute } from "./constants/calendar";
import { homeRoute } from "./constants/home";
import { loginRoute } from "./constants/login";
import { notFoundRoute } from "./constants/notfound";
import { photosRoute } from "./constants/photos";
import { profileRoute } from "./constants/profile";
import { reportsRoute } from "./constants/reports";
import {
  createReservationRoute,
  reservationsRoute,
} from "./constants/reservations";
import { statementsRoute } from "./constants/statements";

const Root = props => {
  const [user, loading] = useCurrentUser();
  const isAuthed = useIsAuthed();
  return (
    !loading && (
      <React.Fragment>
        <AppNavBar currentUser={user} current={props.location.pathname} />
        <AppContainer>
          <Switch>
            <Route path={homeRoute} exact>
              <Home />
            </Route>

            <Route path={aboutRoute} authed>
              <About />
            </Route>
            <Route path={statementsRoute} authed>
              <Statements currentUser={user} />
            </Route>
            <Route path={reportsRoute} authed>
              <Reports currentUser={user} />
            </Route>
            <Route path={photosRoute} authed>
              <Photos currentUser={user} />
            </Route>
            <Route path={photosRoute} authed>
              <Photos currentUser={user} />
            </Route>
            <Route path={calendarRoute} authed>
              <Calendar currentUser={user} />
            </Route>
            <Route path={profileRoute} authed>
              <Profile currentUser={user} />
            </Route>
            <Route
              path={[createReservationRoute(":view"), reservationsRoute]}
              authed
            >
              <Reservations />
            </Route>

            <Route path={loginRoute} authed={false}>
              <Login />
            </Route>

            <Route path={notFoundRoute}>
              <NotFound />
            </Route>
            {isAuthed ? <NotFoundRedirect /> : <LoginRedirect />}
          </Switch>
        </AppContainer>
      </React.Fragment>
    )
  );
};

export default Root;
