import { login, logout, resetPassword } from "utils/firebase/users";

const initialState = { signIn: true, msg: "" };

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_SIGN_IN":
      return {
        ...state,
        msg: "",
        signIn: payload.signIn,
      };
    case "PASSWORD_RESET":
      return {
        ...state,
        msg: "Mail enviado.",
      };
    default:
      return state;
  }
};

const signIn = (credentials, dispatch) => {
  const { email, password } = credentials;
  return login(email, password, true)
    .then(user => dispatch({ type: "SET_SIGN_IN", payload: { signIn: true } }))
    .catch(err => {
      console.log("ERR", err);
      dispatch({ type: "SET_SIGN_IN", payload: { signIn: false } });
      throw err;
    });
};

const signOut = dispatch => {
  dispatch({ type: "SET_SIGN_IN", payload: { signIn: false } });
  return logout();
};

const resetPasswordEmail = async (mail, dispatch) => {
  console.log("ENVIARRR RESET", mail);
  return resetPassword(mail)
    .then(() => dispatch({ type: "PASSWORD_RESET" }))
    .catch(error => dispatch({ type: "PASSWORD_RESET_ERROR" }));
};

export const actions = dispatch => ({
  setSignIn: async credentials => signIn(credentials, dispatch),
  setSignOut: () => signOut(dispatch),
  resetPassword: async mail => resetPasswordEmail(mail, dispatch),
});
