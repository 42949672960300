import * as React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Box, Flex } from "ui";
import LoadingButton from "ui/LoadingButton";

import { useActions } from "utils/redux";

const Move = ({ view, currentUser, route, moving, setMoving }) => {
  const { moveFolder, moveFile } = useActions(actions => actions[view]);

  const onCancelMove = () => setMoving(null);
  const onAcceptMove = () => {
    console.log("mover", moving);
    if (moving.path === route.join("/")) {
      setMoving(null);
      return;
    }
    if (moving.type === "folder") {
      return moveFolder(currentUser, moving, route).then(() => setMoving(null));
    } else {
      return moveFile(currentUser, moving, route).then(() => setMoving(null));
    }
  };
  return (
    <Flex width={1}>
      <Box width={1} textAlign="center">
        ¿Mover aquí?
      </Box>
      <Flex width={1} justifyContent="center">
        <Box mx={1}>
          <LoadingButton
            onClick={onAcceptMove}
            icon={<CheckOutlined />}
            shape="circle"
          />
        </Box>
        <Box mx={1}>
          <LoadingButton
            onClick={onCancelMove}
            icon={<CloseOutlined />}
            type="danger"
            shape="circle"
          />
        </Box>
      </Flex>
    </Flex>
  );
};
export default Move;
