import * as React from "react";
import { useLocation } from "react-router-dom";
import Section from "ui/Section";

import { getFileView, getPageName, MAX_FOLDERS } from "utils/files";
import { useDirectory } from "utils/hooks/firebase/files";

import Toolbar from "./Toolbar/Toolbar";
import FileList from "./FilesList";

const Files = ({ currentUser, route, restricted }) => {
  // Ruta
  const { pathname } = useLocation();
  const view = getFileView(pathname);

  // Actualizar las carpetas y archivos en la ruta actual
  const [dataFolders, dataFiles, loading] = useDirectory(
    getPageName(pathname),
    route
  );

  // Estados con los archivos y carpetas
  const [folders, setFolders] = React.useState(dataFolders);
  const [files, setFiles] = React.useState(dataFiles);

  // Estados para el manejo de crear carpeta, subir archivos o el buscador
  const [newFolderProps, setNewFolder] = React.useState(null);
  const [search, setSearch] = React.useState(null);

  const filterItems = (value = null) => {
    const newSearch = value == null ? search : value;
    setFolders(
      newSearch
        ? dataFolders.filter(folder =>
            folder.name.toLowerCase().includes(newSearch.toLowerCase())
          )
        : dataFolders
    );
    setFiles(
      newSearch
        ? dataFiles.filter(file =>
            file.name.toLowerCase().includes(newSearch.toLowerCase())
          )
        : dataFiles
    );
  };

  React.useEffect(() => {
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFolders, dataFiles]);

  // Hook para mover un archivo o carpeta
  const [moving, setMoving] = React.useState(null);

  // Resetear valores cuando la ruta cambia
  React.useEffect(() => {
    setNewFolder(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const resetNewFolderProps = () => setNewFolder(null);
  const onNewFolder = () => {
    if (
      currentUser != null &&
      currentUser.admin >= restricted &&
      newFolderProps == null &&
      route.length <= MAX_FOLDERS
    ) {
      setNewFolder({
        editing: true,
        resetNewFolderProps,
        restricted,
        item: {
          name: "",
          type: "folder",
          path: route.join("/"),
          authorId: currentUser.id,
        },
      });
    } else {
      resetNewFolderProps();
    }
  };

  const bindSearchbar = {
    value: search,
    onChange: e => {
      const newSearch = e.target.value;
      setSearch(newSearch);
      filterItems(newSearch);
    },
  };

  return (
    <Section
      title={getPageName(pathname)}
      width={1}
      className="trekan-files-section"
    >
      <Toolbar
        currentUser={currentUser}
        route={route}
        restricted={restricted}
        bindSearchbar={bindSearchbar}
        moving={moving}
        setMoving={setMoving}
        onNewFolder={onNewFolder}
      />
      <FileList
        view={view}
        currentUser={currentUser}
        route={route}
        restricted={restricted}
        newFolderProps={newFolderProps}
        folders={folders}
        files={files}
        moving={moving}
        setMoving={setMoving}
        loading={loading}
        resetNewFolderProps={resetNewFolderProps}
      />
    </Section>
  );
};

export default Files;
