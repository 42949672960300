import axios from "axios";
import { Service } from "axios-middleware";

import { getIdToken } from "utils/firebase/users";

let API_URL;
//////////////////////////
///////////////////////////////////////////////////////////////////
////////
API_URL = "https://us-central1-staging-campo.cloudfunctions.net/api";
/////////

const service = new Service(axios);

service.register({
  onRequest(config) {
    const { headers, ...rest } = config;
    return getIdToken().then(token => ({
      ...rest,
      headers: { ...headers, authorization: `Bearer ${token}` },
    }));
  },
  onResponseError(error) {
    throw new Error(error.response.data);
  },
});

const axiosApi = axios.create({ baseURL: API_URL });

export default axiosApi;
