import { useWindowSize } from "react-use";

export const useIsSmaller = (maxWidth: number) => {
  const { width } = useWindowSize();
  return width <= maxWidth;
};

export const useIsMobile = () => {
  return useIsSmaller(600);
};
