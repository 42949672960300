import React from "react";
import { Route } from "react-router-dom";

import Root from "routes";

import Providers from "./Providers";

function App() {
  return (
    <Providers>
      <Route path="/" component={Root} />
    </Providers>
  );
}

export default App;
