import { firestore } from "config";

export default user => {
  return firestore
    .collection("users")
    .doc(user)
    .collection("requests")
    .get()
    .then(querySnapshot => {
      return querySnapshot.docs.length
        ? querySnapshot.docs
            .map(doc => {
              const { dates } = doc.data();
              return Object.values(dates).reduce((a, b) => a + b, 0);
            })
            .reduce((a, b) => a + b, 0)
        : 0;
    });
};
