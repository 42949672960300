import { applyMiddleware, combineReducers, createStore } from "redux";
import { load, save } from "redux-localstorage-simple";
import thunk from "redux-thunk";

const createStoreWithActions = reducersWithActions => {
  const reducers = {};
  Object.keys(reducersWithActions).forEach(
    key => (reducers[key] = reducersWithActions[key].reducer)
  );
  const rootReducer = combineReducers(reducers);
  const middlewares = [thunk, save()];
  if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`);
    const logger = createLogger({
      collapsed: true,
    });
    middlewares.push(logger);
  }
  const store = createStore(
    rootReducer,
    load(),
    applyMiddleware(...middlewares)
  );
  const dispatch = action => {
    return store.dispatch(action);
  };
  const actions = {};
  Object.keys(reducersWithActions).forEach(
    key => (actions[key] = reducersWithActions[key].actions(dispatch))
  );
  return [store, actions];
};

export default createStoreWithActions;
