import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import "./index.less";

import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

// TagManager config
let tagManagerArgs;
//////////////////////////
//////////////////
///////////////////////
//
////////
tagManagerArgs = {
  gtmId: "GTM-M6VKTQ9",
};
/////////

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
