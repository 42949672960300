import * as React from "react";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Box, Button, Checkbox, Form, Input, message, Switch } from "ui";

import { profileRoute } from "routes/constants/profile";
import { updateEmail, updatePassword, updateUser } from "utils/firebase/users";

const emailRules = [
  {
    required: true,
    message: "Por favor ingresa un correo válido!",
  },
];

const passwordRules = [
  {
    required: true,
    message: "Por favor ingresa una contraseña válida!",
  },
  () => ({
    validator(rule, value) {
      if (!value || value.length >= 6) {
        return Promise.resolve();
      }
      return Promise.reject(
        "La contraseña debe tener por lo menos 6 carácteres!"
      );
    },
  }),
];

const confirmRules = [
  {
    required: true,
    message: "Por favor confirma tu contraseña!",
  },
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value && getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }
      return Promise.reject("Las contraseñas no coinciden!");
    },
  }),
];

const EditUser = ({ user }) => {
  const [loading, setLoading] = React.useState(false);
  const { id, firstName, lastName, mail, notifications } = user;
  const [form] = Form.useForm();
  const onFinish = async values => {
    setLoading(true);
    const {
      notifications: formNotifications,
      mail: formMail,
      resetPassword,
      currentPassword,
      newPassword,
      confirm,
    } = values;
    if (formMail !== mail) {
      await updateEmail(formMail, currentPassword);
    }
    const promises = [];
    if (resetPassword && newPassword !== "" && newPassword === confirm) {
      promises.push(updatePassword(currentPassword, newPassword));
    }
    if (formNotifications !== notifications) {
      promises.push(updateUser(id, { notifications: formNotifications }));
    }
    if (promises.length !== 0) {
      return Promise.all(promises)
        .then(() => {
          setLoading(false);
          message.success("Editado con éxito");
        })
        .catch(error => message.error(error.message));
    }
    setLoading(false);
  };
  return (
    <Form
      size="large"
      name="login"
      form={form}
      initialValues={{
        firstName,
        lastName,
        mail,
        notifications,
        resetPassword: false,
      }}
      onFinish={onFinish}
    >
      <Form.Item label="Recibir notificaciones" name="notifications">
        <Switch defaultChecked={notifications} />
      </Form.Item>
      <Form.Item name="mail" hasFeedback rules={emailRules}>
        <Input prefix={<MailOutlined />} placeholder="Correo electrónico" />
      </Form.Item>
      <Form.Item name="currentPassword" rules={passwordRules} hasFeedback>
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Contraseña actual"
        />
      </Form.Item>
      <Form.Item name="resetPassword" valuePropName="checked">
        <Checkbox>Cambiar contraseña</Checkbox>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.resetPassword !== currentValues.resetPassword
        }
      >
        {({ getFieldValue }) =>
          getFieldValue("resetPassword") && (
            <>
              <Form.Item name="newPassword" rules={passwordRules} hasFeedback>
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Nueva contraseña"
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={confirmRules}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Confirmar nueva contraseña"
                />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      <Form.Item>
        <Button width={1} type="primary" htmlType="submit" loading={loading}>
          Guardar
        </Button>
        <Box width={1} mt={2} textAlign="center">
          <Link to={profileRoute}>Cancelar</Link>
        </Box>
      </Form.Item>
    </Form>
  );
};

export default EditUser;
