import * as React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Box, Button, Flex } from "ui";

const Mobile = ({ route, onClickRoute }) => {
  return (
    <Flex display="inline-flex" alignItems="center" justifyContent="stretch">
      <Box>
        <Button
          icon={<ArrowLeftOutlined />}
          shape="circle"
          onClick={() => onClickRoute(route.length - 2)}
          title={route.length > 1 ? route[route.length - 2] : "Nada"}
          disabled={route.length > 1 ? false : true}
        />
      </Box>
      <Box flex={1} textAlign="center">
        {route[route.length - 1]}
      </Box>
    </Flex>
  );
};

export default Mobile;
