import * as React from "react";
import {
  FileAddOutlined,
  FolderAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Box, Button, message, Upload } from "ui";

import { getPageName, MAX_FOLDERS } from "utils/files";
import { uploadFile } from "utils/firebase/files";

const Options = ({ route, currentUser, onNewFolder }) => {
  const { pathname } = useLocation();
  const pageName = getPageName(pathname);

  const [isUploadShowing, setIsUploadShowing] = React.useState(false);
  const onSetUpload = () => setIsUploadShowing(upload => !upload);

  const [list, setList] = React.useState([]);
  const onUpload = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    const onStatusChange = percent => {
      if (percent === 100) {
        onSuccess("Ok");
      } else {
        onProgress({ percent });
      }
    };
    try {
      uploadFile(pageName, currentUser, route.join("/"), file, onStatusChange);
    } catch (err) {
      console.log("Eroor: ", err);
      onError({ err });
    }
  };
  const removeFile = uid =>
    setTimeout(
      () => setList(files => files.filter(file => file.uid !== uid)),
      2000
    );
  const props = {
    name: "file",
    multiple: true,
    fileList: list,
    customRequest: onUpload,
    onChange(info) {
      console.log(info);
      const {
        file: { status, uid, name },
        fileList,
      } = info;
      setList(fileList);
      if (status === "done") {
        message.success(`${name} subido correctamente.`);
        removeFile(uid);
      } else if (status === "error") {
        message.error(`${name} tuvo un problema al subir.`);
        removeFile(uid);
      }
    },
  };
  return (
    <>
      <Box mx={1}>
        <Button
          type="default"
          shape="circle"
          icon={<FolderAddOutlined />}
          title="Crear carpeta"
          disabled={route.length > MAX_FOLDERS ? true : false}
          onClick={onNewFolder}
        />
      </Box>
      <Box mx={1}>
        <Button
          type="default"
          shape="circle"
          icon={<FileAddOutlined />}
          title="Agregar archivo"
          onClick={onSetUpload}
        />
      </Box>
      {isUploadShowing && (
        <Box width={1} mt={2}>
          <Upload.Dragger {...props}>
            <Box className="ant-upload-drag-icon">
              <PlusOutlined />
            </Box>
            <Box className="ant-upload-text">
              Apreta o arrastra archivos para subirlos
            </Box>
          </Upload.Dragger>
        </Box>
      )}
    </>
  );
};

export default Options;
