import * as React from "react";
import { Card } from "ui";

import { useIsMobile } from "utils/hooks/helpers";
import { useActions } from "utils/redux";

import DesktopVersion from "./Desktop";
import MobileVersion from "./Mobile";

const ToolbarBreadcrumb = ({ route, view }) => {
  const { removeRoute } = useActions(actions => actions[view]);

  const isMobile = useIsMobile();

  // Funciones de cambiar la ruta
  const onClickRoute = pos => removeRoute(pos);

  const props = {
    route,
    onClickRoute,
  };
  return (
    <Card width={1} mb={2}>
      {isMobile ? <MobileVersion {...props} /> : <DesktopVersion {...props} />}
    </Card>
  );
};

export default ToolbarBreadcrumb;
