import * as React from "react";
import moment from "moment";
import { useMeasure } from "react-use";
import { Box, Flex } from "ui";
import ListItem from "ui/ListItem";

import { useReservationPersons } from "utils/hooks/firebase/requests";

const Item = ({ reservation }) => {
  const [containerRef] = useMeasure();
  const [[{ firstName, lastName }, admin]] = useReservationPersons(reservation);
  const { from, to, rooms } = reservation;
  const { creatorName, creatorLastName } = admin || {};
  const itemHeight = 170;
  const contentPadding = 10;
  return (
    <ListItem
      ref={containerRef}
      bg="hellblue"
      containerProps={{ height: itemHeight }}
      contentProps={{ p: contentPadding }}
    >
      <Flex flexDirection="column">
        <Box fontSize={18}>{`${firstName} ${lastName}`}</Box>
        <Box>Inicio: {moment(from).format("DD-MM-YYYY")}</Box>
        <Box>Fin: {moment(to).format("DD-MM-YYYY")}</Box>
        <Box>Piezas: {rooms}</Box>
        {admin && (
          <Box>Acumula Puntos: {`${creatorName} ${creatorLastName}`}</Box>
        )}
      </Flex>
    </ListItem>
  );
};

export default Item;
