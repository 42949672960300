import { getFilesActions, getFilesReducer } from "utils/redux/files";

const initialState = {
  routeReports: ["Informes"],
  refReports: "Informes",
};

export const reducer = (state = initialState, action) =>
  getFilesReducer("Reports", state, action);

export const actions = dispatch =>
  getFilesActions("REPORTS", "Informes", dispatch);
