import * as React from "react";
import { ConfigProvider } from "antd";
import es_ES from "antd/lib/locale/es_ES";
import { ThemeProvider } from "pcln-design-system";
import { BrowserRouter as Router } from "react-router-dom";

import CurrentUserProvider from "./CurrentUserProvider";
import ReduxProvider from "./Redux";

const Providers = ({ children }) => (
  <ConfigProvider locale={es_ES}>
    <ThemeProvider>
      <Router>
        <CurrentUserProvider>
          <ReduxProvider>{children}</ReduxProvider>
        </CurrentUserProvider>
      </Router>
    </ThemeProvider>
  </ConfigProvider>
);

export default Providers;
