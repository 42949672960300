import * as React from "react";
import { Button, DatePicker, Form, InputNumber, message, Radio } from "ui";

import { createReservation } from "utils/api/reservations";

const sharedRules = [
  {
    required: true,
    message: "Por favor ingresa si se quiere compartir",
  },
];

const datesRules = [
  {
    required: true,
    message: "Por favor ingresa las fechas",
  },
];

const roomsRules = [
  {
    required: true,
    message: "Por favor ingresa la cantidad de piezas",
  },
];

const Request = () => {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const maxRooms = 4;

  const onFinish = async values => {
    setLoading(true);
    const {
      shared,
      rooms,
      dates: [from, to],
    } = values;
    console.log(from, to);
    try {
      await createReservation({
        shared,
        rooms: rooms ?? maxRooms,
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
      });
      form.resetFields();
      message.success("Fechas pedidas");
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
    setLoading(false);
  };

  return (
    <Form size="large" name="request" form={form} onFinish={onFinish}>
      <Form.Item name="shared" hasFeedback rules={sharedRules}>
        <Radio.Group size="small">
          <Radio.Button value={false}>Solo</Radio.Button>
          <Radio.Button value={true}>Compartir</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.shared !== curr.shared}
      >
        {({ getFieldValue }) =>
          getFieldValue("shared") && (
            <Form.Item name="rooms" hasFeedback rules={roomsRules}>
              <InputNumber
                size="small"
                min={0}
                max={maxRooms}
                placeholder="Piezas"
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item name="dates" hasFeedback rules={datesRules}>
        <DatePicker.RangePicker size="small" />
      </Form.Item>

      <Form.Item>
        <Button width={1} type="primary" htmlType="submit" loading={loading}>
          Solicitar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Request;
