import firebase, { firestore, storage } from "config/firebase";
import { generate } from "short-uuid";

const ADMIN_LEVEL = 100;

// ------ Funciones de carpetas ------
export const updateFolder = async (collection, folder) => {
  const { uuid, updated, ...rest } = folder;
  const id = uuid || generate();

  const obj = {
    ...rest,
    updated: updated?.toDate() || new Date(),
  };
  console.log(obj);

  await firestore.collection(collection).doc(id).set(obj);

  return obj;
};

export const findSubDirs = async (collection, path) => {
  const endPath =
    path.slice(0, -1) + String.fromCharCode(path.slice(-1).charCodeAt(0) + 1);
  const folderRef = firestore.collection(collection);
  const subDirs = await folderRef
    .where("path", ">=", path)
    .where("path", "<", endPath)
    .get();
  return [folderRef, subDirs];
};

export const moveFolder = async (collection, currentUser, folder, newRoute) => {
  if (currentUser.admin < ADMIN_LEVEL)
    throw new Error("No se tiene autorización");

  const folderPath = newRoute.join("/");
  const currentPath = `${folder.path}/${folder.name}`;
  const currentPathLength = currentPath.split("/").length;

  const [, subDirs] = await findSubDirs(collection, currentPath);

  // Actualizar la ubicacion de los archivos y carpetas
  await Promise.all(
    subDirs.docs
      .map(doc => {
        const { path } = doc.data();
        const listPath = path.split("/");
        const newPath = [folderPath, folder.name]
          .concat(listPath.slice(currentPathLength))
          .join("/");
        return moveItem(currentUser, collection, { uuid: doc.id }, newPath);
      })
      .concat([moveItem(currentUser, collection, folder, folderPath)])
  );
};

export const deleteFolder = async (collection, currentUser, folder) => {
  if (currentUser.admin < ADMIN_LEVEL)
    throw new Error("No se tiene autorización");

  const path = `${folder.path}/${folder.name}`;
  const [folderRef, subDirs] = await findSubDirs(collection, path);

  await Promise.all(
    subDirs.docs
      .map(doc => {
        const { type, path, uuid } = doc.data();
        return type === "folder"
          ? deleteFolder(collection, currentUser, { path, uuid })
          : deleteFile(currentUser, { uuid, type });
      })
      .concat([folderRef.doc(folder.uuid).delete()])
  );
};

// ------ Funciones de archivos ------
export const moveItem = (currentUser, collection, item, newPath) => {
  if (currentUser.admin < ADMIN_LEVEL)
    throw new Error("No se tiene autorización");
  const itemRef = firestore.collection(collection).doc(item.uuid);
  return itemRef.update({ path: newPath });
};

export const deleteFile = (currentUser, collection, file) => {
  if (currentUser.admin < ADMIN_LEVEL)
    throw new Error("No se tiene autorización");
  const storageRef = storage.ref(`${collection}/${file.uuid}.${file.type}`);
  const fileRef = firestore.collection(collection).doc(file.uuid);
  return Promise.all([fileRef.delete(), storageRef.delete()]);
};

export const uploadFile = async (
  collection,
  currentUser,
  path,
  file,
  onStatusChange
) => {
  const uuid = generate();
  const listName = file.name.split(".");
  const name = listName.slice(0, listName.length - 1).join(".");
  const type = listName.pop();
  const obj = {
    authorId: currentUser.id,
    name,
    type,
    updated: new Date(),
    path,
  };

  const firestoreRef = firestore.collection(collection).doc(uuid);
  const storageRef = storage.ref(collection).child(`${uuid}.${type}`);

  const uploadTask = storageRef.put(file);
  return uploadTask.on(
    "state_changed",
    snap => {
      onStatusChange(
        Math.min(99, (snap.bytesTransferred / snap.totalBytes) * 100)
      );
      switch (snap.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
        default:
          break;
      }
    },
    error => {
      console.log("Error subiendo", error);
      throw new Error("Error uploading file");
    },
    async () => {
      const url = await uploadTask.snapshot.ref.getDownloadURL();
      const fileObj = { ...obj, url };
      await firestoreRef.set(fileObj);
      onStatusChange(100);
    }
  );
};

export const updateFile = async (collection, file) => {
  const { uuid, updated, ...rest } = file;
  const id = uuid;

  const obj = {
    ...rest,
    updated: new Date(),
  };
  console.log(obj);

  await firestore.collection(collection).doc(id).update(obj);

  return obj;
};
