import * as React from "react";

import { Button } from "./";

const LoadingButton = ({ onClick, ...rest }) => {
  const [loading, setLoading] = React.useState(false);
  const onClickHandler = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };
  return <Button onClick={onClickHandler} loading={loading} {...rest} />;
};

export default LoadingButton;
