import * as React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Box, Flex } from "ui";
import Title from "ui/Title";

const Section = ({
  title,
  headerActions,
  footerActions,
  onBack,
  children,
  variant,
  centered,
  ...rest
}) => (
  <Flex
    width={1}
    flexDirection="column"
    alignItems={centered ? "center" : "flex-start"}
    {...getContainerProps(variant)}
    {...rest}
  >
    <Box>
      <Title>
        <Flex>
          {onBack && (
            <Box mr={2}>
              <ArrowLeftOutlined onClick={onBack} />
            </Box>
          )}
          {title}
        </Flex>
      </Title>
    </Box>
    <Box width={140} height={1} bg="grey" {...getDividerProps(variant)} />
    {headerActions && <Box>{headerActions}</Box>}
    <Box width={1}>{children}</Box>
    {footerActions && (
      <Flex width={1} justifyContent="flex-end">
        <Box mt={5}>{footerActions}</Box>
      </Flex>
    )}
  </Flex>
);

const getContainerProps = variant => {
  switch (variant) {
    case "normal":
      return {
        px: 24,
        py: 4,
      };
    case "info":
      return {
        py: 75,
        alignItems: "center",
      };
    default:
      throw new Error("This variant is not defined");
  }
};

const getDividerProps = variant => {
  switch (variant) {
    case "normal":
      return {
        my: 4,
      };
    case "info":
      return {
        my: 50,
      };
    default:
      throw new Error("This variant is not defined");
  }
};

Section.defaultProps = {
  variant: "normal",
  centered: false,
};

export default Section;
