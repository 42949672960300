import * as React from "react";
import { Box, Flex } from "ui";

import Skeleton from "components/utils/Skeleton";
import { useActions } from "utils/redux";

import Item from "./Item";

const List = ({
  view,
  currentUser,
  route,
  restricted,
  newFolderProps,
  folders,
  files,
  moving,
  setMoving,
  loading,
  resetNewFolderProps,
}) => {
  const { deleteFolder, deleteFile } = useActions(actions => actions[view]);

  const hasFiles = folders.length + files.length > 0 || newFolderProps != null;
  // Funciones de borrar
  const onDeleteFolder = folder => deleteFolder(currentUser, folder);
  const onDeleteFile = file => deleteFile(currentUser, file);

  const itemProps = {
    view,
    route,
    restricted,
    currentUser,
    setMoving,
    resetNewFolderProps,
  };
  return (
    <SkeletonFiles loading={loading}>
      {hasFiles ? (
        <Flex alignItems="stretch" justifyContent="flex-start">
          {newFolderProps != null && (
            <Box p={1} width={[1, 1 / 2, null, 1 / 3, 1 / 4]}>
              <Item
                view={view}
                route={route}
                currentUser={currentUser}
                {...newFolderProps}
              />
            </Box>
          )}
          {folders.map(
            folder =>
              moving?.uuid !== folder.uuid && (
                <Box
                  key={folder.uuid}
                  p={1}
                  width={[1, 1 / 2, null, 1 / 3, 1 / 4]}
                >
                  <Item
                    {...itemProps}
                    item={folder}
                    onDelete={onDeleteFolder}
                  />
                </Box>
              )
          )}
          {moving == null &&
            files.map(file => (
              <Box key={file.uuid} p={1} width={[1, 1 / 2, null, 1 / 3, 1 / 4]}>
                <Item {...itemProps} item={file} onDelete={onDeleteFile} />
              </Box>
            ))}
        </Flex>
      ) : (
        <Flex justifyContent="center">
          <Box>No hay archivos</Box>
        </Flex>
      )}
    </SkeletonFiles>
  );
};

const SkeletonFiles = ({ loading, children }) => {
  return loading ? (
    <Flex alignItems="stretch">
      {Array(4)
        .fill()
        .map((_, i) => (
          <Box key={i} p={1} flex={1} textAlign="center">
            <Skeleton width={200} height={200} loading={loading} />
            <Skeleton width={200} count={3} loading={loading} />
          </Box>
        ))}
    </Flex>
  ) : (
    children
  );
};

export default List;
