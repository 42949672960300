import * as React from "react";
import { firestore } from "config";
import moment from "moment";

export const useDirectory = (collection, route) => {
  const [folders, setFolders] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsuscribre = firestore
      .collection(collection)
      .where("path", "==", route.join("/"))
      .onSnapshot(
        snap => {
          const { dataFolders, dataFiles } = snap.docs.reduce(
            (obj, doc) => {
              const { updated } = doc.data();
              const item = {
                ...doc.data(),
                updated: moment(updated?.toDate()),
                uuid: doc.id,
              };
              const type = item.type === "folder" ? "dataFolders" : "dataFiles";
              return { ...obj, [type]: [...obj[type], item] };
            },
            { dataFolders: [], dataFiles: [] }
          );
          setFolders(dataFolders);
          setFiles(dataFiles);
          setLoading(false);
        },
        error => {
          console.log("ERROR", error);
          setLoading(false);
        }
      );

    return () => {
      setLoading(true);
      return unsuscribre();
    };
  }, [collection, route]);

  return [folders, files, loading];
};
