import React, { useRef, useState } from "react";

import { useWindowSize } from "utils/hooks";
import { useAdmins } from "utils/hooks/firebase/users";
import { useActions } from "utils/redux";
import { useCalendarState } from "utils/redux/calendar";

import CalendarView from "./CalendarView";

const CalendarContainer = ({ currentUser }) => {
  const calendarDate = useCalendarState("calendarDate");
  const events = useCalendarState("events");
  const error = useCalendarState("error");
  // Estado para ver si se muestra el calendario o se piden fechas
  const showing = useCalendarState("showing");
  const { resetError, createRequest, changeDate, setShowing } = useActions(
    actions => actions.calendar
  );
  //useCalendarEvents(updateEvents);

  // Pedir fechas
  const [form, setValues] = useState({
    startDate: null,
    endDate: null,
    share: true,
  });
  const [focusedInput, setFocused] = useState("startDate");

  // Noches acumuladas
  const [admins] = useAdmins(100);

  // Tolbar
  const today = new Date();
  //const [calendarDate, setDate] = useState(today);
  const onMonthSelect = (year, month, day = 0) => {
    const newDate =
      month < 0
        ? new Date(year - 1, 12, day, 0, 0, 0)
        : month > 12
        ? new Date(year + 1, 1, day, 0, 0)
        : new Date(year, month, day, 0, 0);
    changeDate(newDate);
  };
  const pickMonthRef = useRef("pickAMonth");

  //Detalle del dia en el celular
  const [daySummary, setDaySummary] = useState(null);

  // Tamaño de pantalla
  const windowSize = useWindowSize();

  return (
    <CalendarView
      currentUser={currentUser}
      showing={showing}
      setShowing={show => {
        setShowing(show);
        if (show === "calendar") {
          setValues({
            startDate: null,
            endDate: null,
            share: true,
          });
          resetError();
        }
      }}
      form={form}
      setValues={setValues}
      focusedInput={focusedInput}
      setFocused={setFocused}
      today={today}
      events={events}
      calendarDate={calendarDate}
      onMonthSelect={onMonthSelect}
      pickMonthRef={pickMonthRef}
      createRequest={() => {
        if (form.startDate && form.endDate) {
          return createRequest(currentUser, form).then(success => {
            if (success) {
              setValues({
                startDate: null,
                endDate: null,
                share: true,
              });
              resetError();
              setShowing("calendar");
            }
          });
        } else {
          return resetError("Se deben ingresar fechas.");
        }
      }}
      error={error}
      daySummary={daySummary}
      setDaySummary={setDaySummary}
      admins={admins}
      windowSize={windowSize}
    />
  );
};

export default CalendarContainer;
