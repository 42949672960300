import firebase, { auth, database, emailAuthProvider } from "config/firebase";

const setPersistence = remember => {
  const persistence = remember
    ? // eslint-disable-next-line import/no-named-as-default-member
      firebase.auth.Auth.Persistence.LOCAL
    : // eslint-disable-next-line import/no-named-as-default-member
      firebase.auth.Auth.Persistence.SESSION;
  auth.setPersistence(persistence);
};

export const getUser = uid =>
  uid &&
  database
    .ref("users")
    .child(uid)
    .once("value")
    .then(snap => {
      return { ...snap.val(), id: snap.key };
    });

export const updateUser = (uid, user) =>
  database.ref("users").child(uid).update(user);

export const login = async (email, password, remember) => {
  await setPersistence(remember);
  const user = await auth.signInWithEmailAndPassword(email, password);
  if (remember) {
    saveToLocalStorage(user);
  }
  return user;
};

export const logout = () =>
  auth.signOut().then(() => window.localStorage.removeItem("user"));

export const resetPassword = email => auth.sendPasswordResetEmail(email);

export const updatePassword = async (current, password) => {
  const credential = await emailAuthProvider.credential(
    auth.currentUser.email,
    current
  );
  return auth.currentUser
    .reauthenticateAndRetrieveDataWithCredential(credential)
    .then(() => auth.currentUser.updatePassword(password));
};

export const updateEmail = async (mail, password) => {
  const credential = await emailAuthProvider.credential(
    auth.currentUser.email,
    password
  );
  await auth.currentUser.reauthenticateAndRetrieveDataWithCredential(
    credential
  );
  const user = auth.currentUser;
  return user.updateEmail(mail).then(() => updateUser(user.uid, { mail }));
};

const saveToLocalStorage = (user: { uid: String, email: string }) => {
  const { uid, email } = user;
  window.localStorage.setItem("user", { uid, email });
};

export const getIdToken = () =>
  auth.currentUser?.getIdToken(true) ??
  new Promise((resolve, reject) => resolve(""));
