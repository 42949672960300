import { useSelector } from "./";

export const useCalendarState = key =>
  useSelector(state => {
    const keyState = state.calendar[key];
    return key === "calendarDate"
      ? new Date(keyState)
      : key === "events"
      ? keyState.map(event => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
          deadline: new Date(event.deadline),
        }))
      : keyState;
  });
