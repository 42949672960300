import { firestore } from "config";

export default (collectionRef, batchSize) => {
  const ref = firestore.collection(collectionRef);
  const query = ref.orderBy("__name__").limit(batchSize);
  return new Promise((resolve, reject) => {
    deleteQueryBatch(query, batchSize, resolve, reject);
  });
};

const deleteQueryBatch = (query, batchSize, resolve, reject) => {
  query
    .get()
    .then(snapshot => {
      if (snapshot.size === 0) {
        return 0;
      }

      let batch = firestore.batch();
      snapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      return batch.commit().then(() => {
        return snapshot.size;
      });
    })
    .then(numDeleted => {
      if (numDeleted === 0) {
        resolve();
        return;
      }

      process.nextTick(() => {
        deleteQueryBatch(query, batchSize, resolve, reject);
      });
    })
    .catch(reject);
};
