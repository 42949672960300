import React from "react";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, message } from "ui";
import Section from "ui/Section";

import { homeRoute } from "routes/constants/home";
import { useActions } from "utils/redux";

const emailRules = [
  {
    required: true,
    message: "Por favor ingresa un correo válido!",
  },
];

const passwordRules = [
  {
    required: true,
    message: "Por favor ingresa una contraseña válida!",
  },
];

const Login = () => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const { setSignIn, resetPassword } = useActions(actions => actions.login);

  const [form] = Form.useForm();

  const onFinish = values => {
    setLoading(true);
    return setSignIn(values)
      .then(() => {
        setLoading(false);
        history.push(homeRoute);
      })
      .catch(() => {
        setLoading(false);
        message.error(
          "Ha ocurrido un error, por favor intenta nuevamente más tarde"
        );
      });
  };
  const onReset = () => {
    const email = form.getFieldValue("email");
    if (email == null || email === "") {
      message.error("Debes ingresar un correo");
    } else {
      return resetPassword(email)
        .then(() => message.success("Correo enviado"))
        .catch(error => message.error(error.message));
    }
  };

  return (
    <Section centered title="Iniciar sesión" bg="white">
      <Form
        size="large"
        name="login"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item name="email" hasFeedback rules={emailRules}>
          <Input prefix={<MailOutlined />} placeholder="Correo electrónico" />
        </Form.Item>
        <Form.Item name="password" rules={passwordRules} hasFeedback>
          <Input.Password prefix={<LockOutlined />} placeholder="Contraseña" />
        </Form.Item>
        <Form.Item>
          <Button width={1} type="primary" htmlType="submit" loading={loading}>
            Inicia sesión
          </Button>
        </Form.Item>
      </Form>
      <Button type="link" onClick={onReset}>
        Resetear contraseña
      </Button>
    </Section>
  );
};

export default Login;
