import * as React from "react";
import { database } from "config";

import { useUser } from "./users";

export const useCalendarEvents = month => {
  const [downloaded, setDownloaded] = React.useState({});
  const [state, setState] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (downloaded[month]) {
      setState(downloaded[month]);
      setLoading(false);
    } else {
      setLoading(true);
    }

    const ref = database
      .ref("requests")
      .orderByChild(`months/${month}`)
      .equalTo(true);

    ref.on(
      "value",
      snap => {
        const entries = Object.entries(snap.val() || {});
        const values = entries.map(([id, value]) => ({ id, ...value }));
        setState(values);
        setDownloaded(val => {
          val[month] = values;
          return val;
        });
        setLoading(false);
      },
      error => {
        console.log(error);
        setState([]);
        setLoading(false);
      }
    );

    return () => {
      setLoading(false);
      ref.off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return [state, loading];
};

export const useReservationPersons = reservation => {
  const { user: userId, pointsUser: pointsUserId } = reservation;

  const [user, loadingUser] = useUser(userId);
  const [pointsUser, loadingPointsUser] = useUser(
    userId !== pointsUserId && pointsUserId
  );

  return [
    [user, Object.values(pointsUser).length > 0 ? pointsUser : null],
    loadingUser || loadingPointsUser,
  ];
};
