import * as React from "react";
import { Box, Flex } from "ui";

const Container = ({
  p,
  width,
  maxWidth,
  header,
  footer,
  children,
  ...props
}: BoxProps): React.Node => {
  return (
    <Flex justifyContent="center" {...props}>
      {header && (
        <Box width={1} height={64} position="fixed" zIndex={500} bg="grey" />
      )}
      <Box mt={header ? 64 : 0} p={p} width={width} maxWidth={maxWidth}>
        {children}
      </Box>
    </Flex>
  );
};

Container.defaultProps = {
  header: false,
  footer: false,
};

export default Container;
