import * as React from "react";

import Files from "components/Files/Files";
import { useFilesState } from "utils/redux/files";

const PhotosContainer = ({ currentUser }) => {
  const restricted = useFilesState("restrictedPhotos");
  const route = useFilesState("routePhotos");

  return (
    <Files currentUser={currentUser} route={route} restricted={restricted} />
  );
};

export default PhotosContainer;
