import * as React from "react";
import { Box } from "ui";

const Title = ({ children }) => (
  <Box width={1} fontSize={[25, 30]} fontWeight="medium" color="grey">
    {children}
  </Box>
);

export default Title;
