import * as React from "react";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "ui";

const Desktop = ({ route, onClickRoute }) => {
  return (
    <Breadcrumb>
      {route.map((name, pos) => (
        <Breadcrumb.Item key={pos}>
          <Button type="link" onClick={() => onClickRoute(pos)}>
            {pos === 0 ? <HomeOutlined /> : name}
          </Button>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Desktop;
