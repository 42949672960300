import {
  deleteFile,
  deleteFolder,
  moveFolder,
  moveItem,
  uploadFile,
} from "utils/firebase/files";

import { useSelector } from "./";

export const useFilesState = key =>
  useSelector(state => {
    const keyState = state.files[key];
    return key === "restricted" ? keyState[keyState.length - 1] : keyState;
  });

export const useReportsState = key => useSelector(state => state.reports[key]);

export const useStatementsState = key =>
  useSelector(state => state.statements[key]);

export const getFilesReducer = (_type, state, action) => {
  const type = _type.toUpperCase();
  const { type: actionType, payload } = action;
  switch (actionType) {
    case `RESET_${type}`:
      return {
        [`route${_type}`]: ["Fotos"],
        [`ref${_type}`]: "Fotos",
        [`restricted${_type}`]: [false],
      };
    case `ADD_ROUTE_${type}`:
      return {
        [`route${_type}`]: state[`route${_type}`].concat([payload.route]),
        [`ref${_type}`]: state[`ref${_type}`].concat(
          `/${payload.uuid}/directory`
        ),
        [`restricted${_type}`]: state[`restricted${_type}`].concat([
          payload.restricted,
        ]),
      };
    case `REMOVE_ROUTE_${type}`:
      return {
        [`route${_type}`]: state[`route${_type}`].slice(0, payload + 1),
        [`ref${_type}`]: state[`ref${_type}`]
          .split("/")
          .slice(0, payload * 2 + 1)
          .join("/"),
        [`restricted${_type}`]: state[`restricted${_type}`].slice(
          0,
          payload + 1
        ),
      };
    default:
      return state;
  }
};

export const getFilesActions = (type, collection, dispatch) => ({
  resetState: () => {
    dispatch({ type: `RESET_${type}` });
  },
  addRoute: (route, uuid, restricted) => {
    dispatch({
      type: `ADD_ROUTE_${type}`,
      payload: { route, uuid, restricted },
    });
  },
  removeRoute: position =>
    position === 0
      ? dispatch({ type: `RESET_${type}` })
      : dispatch({ type: `REMOVE_ROUTE_${type}`, payload: position }),
  updateState: payload => {
    // Actualizar estado
    dispatch({ type: `UPDATE_${type}`, payload });
  },
  moveFolder: async (currentUser, folder, route) => {
    try {
      await moveFolder(collection, currentUser, folder, route);
      return dispatch({ type: `SET_FOLDERS_${type}`, payload: folder });
    } catch (error) {
      return dispatch({
        type: `MOVE_FOLDER_ERROR_${type}`,
        payload: error,
      });
    }
  },
  deleteFolder: async (currentUser, folder) => {
    try {
      await deleteFolder(collection, currentUser, folder);
      return dispatch({ type: `DELETE_FOLDER_${type}`, payload: folder });
    } catch (error) {
      return dispatch({
        type: `DELETE_FOLDER_ERROR_${type}`,
        payload: error,
      });
    }
  },
  uploadFile: async (path, file, currentUser) => {
    try {
      const payload = await uploadFile(collection, currentUser, path, file);
      return dispatch({
        type: `SET_FILES_${type}`,
        payload,
      });
    } catch (error) {
      console.log(error);
      return dispatch({ type: `UPLOAD_FILE_ERROR_${type}` }, error);
    }
  },
  updateFile: async file => {},
  moveFile: async (currentUser, file, newRoute) => {
    try {
      await moveItem(currentUser, collection, file, newRoute.join("/"));
      return dispatch({ type: `SET_FILES_${type}`, payload: file });
    } catch (error) {
      return dispatch({
        type: `MOVE_FILE_ERROR_${type}`,
        payload: error,
      });
    }
  },
  deleteFile: async (currentUser, file) => {
    try {
      await deleteFile(currentUser, collection, file);
      return dispatch({ type: `DELETE_FILE_${type}`, payload: file });
    } catch (error) {
      return dispatch({
        type: `DELETE_FILE_ERROR_${type}`,
        payload: error,
      });
    }
  },
});
