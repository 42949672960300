import * as React from "react";
import { Box, Divider, Flex } from "ui";

const Section = ({ title, subtitle, children, variant, centered, ...rest }) => (
  <Flex
    width={1}
    flexDirection="column"
    alignItems={centered ? "center" : "flex-start"}
    {...rest}
  >
    <Box fontSize={20} mb={2}>
      {title}
    </Box>
    <Box textAlign="center">{subtitle}</Box>
    <Divider />
    <Box width={1}>{children}</Box>
  </Flex>
);

export default Section;
