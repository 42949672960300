import * as React from "react";
import { useLocation } from "react-router-dom";
import { Box, Divider, Flex, Input } from "ui";

import { getFileView } from "utils/files";

import Breadcrumb from "./Breadcrumb";
import MoveItem from "./MoveItem";
import Options from "./Options";

const Toolbar = ({
  currentUser,
  route,
  restricted,
  bindSearchbar,
  moving,
  setMoving,
  onNewFolder,
}) => {
  const { pathname } = useLocation();
  const view = getFileView(pathname);
  // Ver si hay algo moviendose
  const isMoving = moving != null;
  const isUserAllowed = currentUser.admin >= restricted;
  return (
    <Flex flexDirection="vertical" alignItems="stretch">
      <Breadcrumb view={view} route={route} />
      {isMoving ? (
        <MoveItem
          view={view}
          currentUser={currentUser}
          route={route}
          moving={moving}
          setMoving={setMoving}
        />
      ) : (
        <Flex alignItems="center" justifyContent="stretch" width={1}>
          <Box flex={2}>
            <Input id="search" placeholder="Buscador" {...bindSearchbar} />
          </Box>
          {isUserAllowed && (
            <Options
              route={route}
              currentUser={currentUser}
              onNewFolder={onNewFolder}
            />
          )}
        </Flex>
      )}
      <Box width={1} mt={2}>
        <Divider />
      </Box>
    </Flex>
  );
};

export default Toolbar;
