import * as React from "react";
import { Link } from "react-router-dom";
import { Container, Flex } from "ui";

import Route from "components/utils/Route";
import {
  allUsersRoute,
  newUserRoute,
  profileRoute,
} from "routes/constants/profile";
import { useUsers } from "utils/hooks/firebase/users";

import UserList from "./List";
import NewUser from "./New";

const Users = ({ currentUser }) => {
  const [users, loading] = useUsers();
  return (
    <Container width={1}>
      <Route path={allUsersRoute} exact>
        <Flex justifyContent="space-between" mb={3}>
          <Link to={profileRoute}>Menu</Link>
          <Link to={newUserRoute}>Crear</Link>
        </Flex>
        <UserList
          currentUser={currentUser}
          users={users || []}
          loading={loading}
        />
      </Route>
      <Route path={newUserRoute}>
        <NewUser users={users || []} loading={loading} />
      </Route>
    </Container>
  );
};

export default Users;
