import * as React from "react";
import { auth, database } from "config";

export const CurrentUserContext = React.createContext();

const CurrentUserProvider = ({ children }) => {
  const value = useCurrentUser();
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

const useCurrentUser = () => {
  const initialUser = auth.currentUser ?? localStorage.getItem("user");
  const initialState = {
    loading: true,
    user: initialUser
      ? {
          id: initialUser?.uid,
          email: initialUser?.email,
        }
      : null,
  };
  const lastCallId = React.useRef(0);
  const [state, setState] = React.useState(initialState);
  const setUser = React.useCallback(
    (id, callId) =>
      database
        .ref("users")
        .child(id)
        .on(
          "value",
          snap => {
            callId === lastCallId.current &&
              setState({
                user: { id: snap.key, ...snap.val() },
                loading: false,
              });
          },
          error => {
            console.error(error);
            callId === lastCallId.current &&
              setState({ error, loading: false });
          }
        ),
    []
  );
  React.useEffect(() => {
    auth.onAuthStateChanged(user => {
      const callId = ++lastCallId.current;
      if (user) {
        setUser(user.uid, callId);
      } else {
        database.ref("users").off();
        setState({ loading: false });
      }
    });
  }, [setUser]);
  const { user, loading, error } = state;
  return [user, loading, error];
};

export default CurrentUserProvider;
