import * as React from "react";
import { database } from "config";

import { CurrentUserContext } from "components/App/Providers/CurrentUserProvider";

import { useDatabaseValue, useDatabaseValues } from "./utils";

export const useUser = uid =>
  useDatabaseValue(uid && database.ref("users").child(uid), [uid]);

export const useUsers = () => useDatabaseValues(database.ref("users"));

export const useAdmins = role =>
  useDatabaseValues(database.ref("users").orderByChild("admin").startAt(role), [
    role,
  ]);

export const useCurrentUser = () => React.useContext(CurrentUserContext);

export const useIsAuthed = (): boolean => {
  const [user, loading] = useCurrentUser();
  return !loading && Boolean(user);
};

export const useHasRole = (role: ?number): boolean => {
  const [user] = useCurrentUser();
  const isAuthed = useIsAuthed();
  if (role == null) return true;
  return isAuthed && user.admin >= role;
};
