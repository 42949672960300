import * as React from "react";
import { Switch, useHistory } from "react-router-dom";
import { Box, Button, Container, Divider, Flex } from "ui";
import Section from "ui/Section";

import NotFoundRedirect from "components/utils/NotFoundRedirect";
import Route from "components/utils/Route";
import {
  allUsersRoute,
  editRoute,
  profileRoute,
} from "routes/constants/profile";

import EditUser from "./Edit";
import Users from "./Users";

const Profile = ({ currentUser }) => {
  const history = useHistory();

  const { firstName, lastName, admin } = currentUser;

  const onClickEdit = () => history.push(editRoute);
  const onClickAllUsers = () => history.push(allUsersRoute);

  // Límite de admin para ver a todos los usuarios y poder manejarlos
  const allUsersLimit = 100;
  return (
    <Container width={[1, 1, 1, 4 / 5]} className="trekan-profile-container">
      <Section title="Configuración" mt={20}>
        <Box fontSize={20} textAlign="center">{`${firstName} ${lastName}`}</Box>
        <Divider />
        <Switch>
          <Route path={profileRoute} authed exact>
            <Flex justifyContent="center">
              <Box mr={2}>
                <Button onClick={onClickEdit}>Editar</Button>
              </Box>
              {admin >= allUsersLimit && (
                <Box>
                  <Button onClick={onClickAllUsers}>Usuarios</Button>
                </Box>
              )}
            </Flex>
          </Route>
          <Route path={editRoute} authed>
            <EditUser user={currentUser} />
          </Route>
          <Route path={allUsersRoute} authed admin={allUsersLimit}>
            <Users user={currentUser} />
          </Route>
          <NotFoundRedirect />
        </Switch>
      </Section>
    </Container>
  );
};

export default Profile;
