import * as React from "react";

import NotFoundRedirect from "components/utils/NotFoundRedirect";
import { useHasRole, useIsAuthed } from "utils/hooks/firebase/users";

const Authed = ({ children, not, header, admin }) => {
  const isAuthed = useIsAuthed();
  const hasRole = useHasRole(admin);
  const shouldRender = (not ? !isAuthed : isAuthed) && hasRole;
  return shouldRender ? children : <NotFoundRedirect />;
};

Authed.defaultProps = {
  not: false,
  header: false,
  role: null,
};

export default Authed;
