import { getFilesActions, getFilesReducer } from "utils/redux/files";

const initialState = {
  foldersPhotos: {},
  filesPhotos: {},
  routePhotos: ["Fotos"],
  refPhotos: "Fotos",
  restrictedPhotos: [false],
};

export const reducer = (state = initialState, action) =>
  getFilesReducer("Photos", state, action);

export const actions = dispatch => getFilesActions("PHOTOS", "Fotos", dispatch);
