// @flow strict
import * as React from "react";
import { Flex } from "ui";
import Dot from "ui/Dot";

const Legend = ({ requested, available, confirmed }) => {
  return (
    <Flex color="grey" fontSize={14} fontWeight="light">
      {requested && (
        <Flex alignItems="center" mr={4}>
          <Dot color="green" size={15} />
          Pedido
        </Flex>
      )}
      {available && (
        <Flex alignItems="center" mr={4}>
          <Dot color="white" bordered size={15} />
          Disponible
        </Flex>
      )}
      {confirmed && (
        <Flex alignItems="center">
          <Dot color="red" size={15} />
          Confirmado
        </Flex>
      )}
    </Flex>
  );
};

export default Legend;
