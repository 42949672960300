const root = "/reservas";

export const reservationsRoute = root;

export const createReservationRoute = param => `${root}/${param}`;

export const calendarRoute = createReservationRoute("calendario");

export const requestRoute = createReservationRoute("solicitud");

export const criteriaRoute = createReservationRoute("criterios");

export const priorityRoute = createReservationRoute("prioridad");
