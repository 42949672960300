import * as React from "react";
import { Box, Image } from "ui";

import fileImage from "assets/images/file.png";
import folderImage from "assets/images/folder.png";
import { downloadLink } from "utils/files";
import { useActions } from "utils/redux";

import Edit from "./Edit";
import Info from "./Info";

const Item = ({
  view,
  route,
  restricted,
  currentUser,
  item,
  editing,
  onDelete,
  setMoving,
  resetNewFolderProps,
}) => {
  const { addRoute } = useActions(actions => actions[view]);

  const [isEditing, setEditing] = React.useState(editing);

  const { type, name, restricted: folderRestriction, uuid, url } = item;

  const onEdit = () =>
    setEditing(edit => {
      resetNewFolderProps();
      return !edit;
    });
  const onMove = () => setMoving(item);
  const onClick = async () => {
    if (isEditing) return;
    switch (type) {
      case "folder":
        addRoute(name, uuid, folderRestriction);
        break;
      default:
        downloadLink(url);
        break;
    }
  };

  const allowMenu = currentUser.admin >= restricted;
  const props = {
    item,
    onEdit,
    restricted,
  };
  return (
    <Box height="100%" maxWidth="500px" mr={4} ml={4}>
      <Box width={1} height={"65%"} onClick={onClick}>
        <center>
          <Image maxWidth={"75%"} src={getImageSource(item)} />
        </center>
      </Box>
      {isEditing ? (
        <Edit currentUser={currentUser} route={route} {...props} />
      ) : (
        <Info
          onDelete={onDelete}
          onMove={onMove}
          allowMenu={allowMenu}
          {...props}
        />
      )}
    </Box>
  );
};

const getImageSource = item => {
  const { type, url } = item;
  switch (type.toLowerCase()) {
    case "folder":
      return folderImage;
    case "png":
    case "jpg":
      return url;
    default:
      return fileImage;
  }
};

Item.defaultProps = {
  editing: false,
};

export default Item;
