import * as React from "react";

const useFocusInput = () => {
  const [isEditing, setEditing] = React.useState(false);
  const inputRef = React.useRef();
  const toggleEditing = state => {
    setEditing(state ? state : !isEditing);
  };

  React.useEffect(() => {
    if (isEditing) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isEditing]);

  return [inputRef, toggleEditing];
};

export default useFocusInput;
