// @flow strict
import * as React from "react";
import cx from "classnames";
import { Box } from "ui";

const Cell = ({ date, past, disabled, requested, confirmed, today }) => {
  return (
    <Box
      textAlign="center"
      className={cx({
        "trekan-reservations-calendar-cell-past": !disabled && past,
        "trekan-reservations-calendar-cell-available":
          !past && !requested && !confirmed,
        "trekan-reservations-calendar-cell-requested": !past && requested,
        "trekan-reservations-calendar-cell-confirmed": !past && confirmed,
        "trekan-reservations-calendar-cell-today": today,
        "trekan-reservations-calendar-cell-disabled": disabled,
      })}
    >
      {date.format("DD")}
    </Box>
  );
};

export default Cell;
